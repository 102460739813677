<template>
  <div>
    <table
      class="table table-sm"
    >
      <thead>
        <tr>
          <th style="width: 50px">
            Status
          </th>
          <th style="width: 150px">
            Date
          </th>
          <th style="min-width: 200px;">
            Checked by
          </th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="checkups.length === 0">
          <td
            colspan="4"
          >
            No previous checkups found
          </td>
        </tr>
        <tr
          v-for="checkup in checkups"
          :key="checkup.id"
        >
          <td>
            <b-avatar
              v-if="checkup.status === 0"
              variant="success"
              size="sm"
            >
              <feather-icon icon="CheckIcon" />
            </b-avatar>
            <span
              v-if="checkup.status === 1"
              class="b-avatar b-avatar-sm badge-warning rounded-circle"
            />
            <b-avatar
              v-if="checkup.status === 2"
              variant="danger"
              size="sm"
            >
              <feather-icon icon="AlertCircleIcon" />
            </b-avatar>
          </td>
          <td>{{ checkup.checkedOn | formatDate }}</td>
          <td>
            <template
              v-if="checkup.checkedBy"
            >
              <b-avatar
                size="28"
                variant="light-primary"
                class="profile-image"
                :src="checkup.checkedBy.profileImageUrl"
              />
              {{ checkup.checkedBy.firstname }} {{ checkup.checkedBy.lastname }}
            </template>
            <template v-else>
              <!-- checkedBy is NULL -->
              <b-avatar
                size="28"
                variant="light-primary"
                class="profile-image"
              />
              N/A
            </template>
          </td>
          <td>{{ checkup.note }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    checkups: {
      type: Array,
      required: true,
    },
  },
}

</script>
