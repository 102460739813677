<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isAssetListLoading">
          <Grid
            class="grid-full-height"
            :data-items="result"
            :columns="columns"
            :column-menu="true"
            :take="dataState.take"
            :skip="dataState.skip"
            :sort="dataState.sort"
            :filter="dataState.filter"
            :group="dataState.group"
            :expand-field="'expanded'"
            :sortable="true"
            :reorderable="true"
            :resizable="true"
            :groupable="false"
            :pageable="true"
            :filterable="true"
            :page-size="50"
            @datastatechange="onDataStateChange"
            @filterchange="onFilterChange"
            @sortchange="onSortChange"
            @columnreorder="onColumnReorder"
          >
            <!-- Custom toolbar -->
            <GridToolbar>

              <toolbar-item-view-manager
                :grid-id="gridId"
                :columns="columns"
                :data-state="dataState"
                :original-columns="originalColumns"
                :original-data-state="originalDataState"
                :current-view-name="currentViewName"
                :current-view-id="currentViewId"
                @resetToDefaultViewClick="resetToDefaultView"
                @applyView="onApplyView"
              />

              <toolbar-item-column-manager
                :columns="activeColumns"
                :original-columns="originalColumns"
                @columnssubmit="columns = $event"
              />

              <toolbar-item-export-excel
                :data-items="$store.getters['assetList/assets']"
                :columns="activeColumns"
                :data-state="dataState"
              />

              <template>
                <div
                  style="margin-left: auto"
                >
                  <b-button-group>
                    <b-button
                      size="sm"
                      :variant="selectedFilter === 'active' ? 'primary' : 'secondary'"
                      @click="selectedFilter = 'active'"
                    >
                      Active
                    </b-button>
                    <b-button
                      size="sm"
                      :variant="selectedFilter === 'inactive' ? 'primary' : 'secondary'"
                      @click="selectedFilter = 'inactive'"
                    >
                      Inactive
                    </b-button>
                  </b-button-group>
                </div>
              </template>
            </GridToolbar>

            <!-- Custom action cell -->
            <template v-slot:cellActionTemplate="{ props }">
              <DetailLinkActionCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                to="asset-detail-page"
                :to-params="{ id: props.dataItem.id}"
              />
            </template>

            <template v-slot:dailyCheckCellTemplate="{ props }">
              <DailyCheckCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                :asset-id="props.dataItem.id"
                :has-pro-active-service="props.dataItem.hasProActiveService"
                :today-checkup-status="props.dataItem.todayCheckupStatus"
                :is-loading="props.dataItem.checkupLoading"
                @click="onDailyCheckClick(props.dataItem)"
              />
            </template>
          </Grid>
        </b-overlay>
      </b-col>
    </b-row>

    <DailyCheckModal
      ref="dailyCheckModal"
      @dailyCheckupUpdate="onDailyCheckupUpdate"
    />
  </div>
</template>
<script>
import {
  BCol, BRow, BButtonGroup, BButton, BOverlay,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
import { mapGetters, mapMutations } from 'vuex'
import {
  DetailLinkActionCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel, DailyCheckCellTemplate,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'
import DailyCheckModal from '@/components/dailycheck/DailyCheckModal.vue'
import DailyCheckupService from '@/service/dailycheckup.service'

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BOverlay,
    Grid,
    GridToolbar,
    DetailLinkActionCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
    DailyCheckCellTemplate,
    DailyCheckModal,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  data() {
    return {
      gridId: 'asset',
      selectedFilter: 'active',
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
          columnMenu: false,
        },
        {
          cell: 'dailyCheckCellTemplate',
          field: 'dailyCheckCellTemplate',
          title: 'Daily check',
          orderIndex: 1,
          width: '40px',
          filterable: false,
          sortable: false,
          groupable: false,
          columnMenu: false,
        },
        {
          field: 'account.name',
          title: 'Account',
          orderIndex: 2,
          width: '300px',
        },
        {
          field: 'account.accountManager.fullname',
          title: 'Account Manager',
          orderIndex: 3,
          width: '300px',
          hidden: true,
        },
        {
          field: 'organizationUnit.displayName',
          title: 'Organization unit',
          orderIndex: 4,
          width: '300px',
        },
        {
          field: 'systemNumber',
          title: 'Systemnumber',
          orderIndex: 5,
          width: '200px',
        },
        {
          field: 'manufacturer',
          title: 'Manufacturer',
          orderIndex: 6,
          width: '150px',
        },
        {
          field: 'productFamily',
          title: 'Product family',
          orderIndex: 7,
          width: '150px',
        },
        {
          field: 'model',
          title: 'Model',
          orderIndex: 8,
          width: '150px',
        },
        {
          field: 'hostname',
          title: 'Hostname',
          filter: 'text',
          orderIndex: 9,
          width: '300px',
        },
        {
          field: 'hostId',
          title: 'Host Id',
          filter: 'text',
          orderIndex: 10,
          width: '200px',
        },
        {
          field: 'version',
          title: 'Version',
          orderIndex: 11,
          width: '200px',
        },
        {
          field: 'serialnumber',
          title: 'Serialnumber',
          orderIndex: 12,
          width: '200px',
        },
        {
          field: 'dellServiceTag',
          title: 'Dell Service Tag',
          orderIndex: 13,
          width: '200px',
        },
        {
          field: 'ipAddress',
          title: 'IP Address',
          orderIndex: 14,
          width: '150px',
        },
        {
          field: 'location',
          title: 'Location',
          orderIndex: 15,
          width: '150px',
        },
        {
          field: 'licensedCapacityTb',
          title: 'Licensed Capacity (Tb)',
          orderIndex: 16,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'measuredCapacityTb',
          title: 'Measured Capacity (Tb)',
          orderIndex: 17,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'clients',
          title: 'Clients',
          orderIndex: 18,
          width: '100px',
          filter: 'numeric',
          type: 'numeric',
        },
        {
          field: 'expiresOn',
          title: 'Service expires on',
          orderIndex: 19,
          width: '150px',
          filter: 'date',
          type: 'date',
          format: '{0:d}',
        },
        {
          field: 'manufacturerExpiresOn',
          title: 'Vendor expires on',
          orderIndex: 20,
          width: '150px',
          filter: 'date',
          type: 'date',
          format: '{0:d}',
        },
        {
          field: 'hasProActiveService',
          title: 'Has ProActive Service',
          orderIndex: 21,
          width: '150px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
        },
        {
          field: 'bundleDisplayName',
          title: 'Bundle',
          orderIndex: 22,
          width: '150px',
        },
        {
          field: 'medialineBusinessUnitDisplayName',
          title: 'Medialine BU',
          orderIndex: 23,
          width: '200px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isAssetListLoading: 'assetList/isLoading',
      isHost: 'auth/isHost',
    }),
    result() {
      let assets = this.$store.getters['assetList/assets']

      if (this.selectedFilter === 'active') {
        assets = assets.filter(x => x.status === 1)
      } else if (this.selectedFilter === 'inactive') {
        assets = assets.filter(x => x.status === 2)
      }

      /* Normalize data  */
      /* DateTimes must be Date objects to work with grid filters. Booleans doesnt work (Telerik bug?), so we convert boolean to string so that we can filter for string */
      assets.forEach(item => {
        if (item.expiresOn !== null) {
          // eslint-disable-next-line no-param-reassign
          item.expiresOn = new Date(item.expiresOn)
        }
        if (item.manufacturerExpiresOn !== null) {
          // eslint-disable-next-line no-param-reassign
          item.manufacturerExpiresOn = new Date(item.manufacturerExpiresOn)
        }
        if (item.autosupportUpdatedOn !== null) {
          // eslint-disable-next-line no-param-reassign
          item.autosupportUpdatedOn = new Date(item.autosupportUpdatedOn)
        }

        if (item.account && item.account.accountManager) {
          // eslint-disable-next-line no-param-reassign
          item.account.accountManager.fullname = `${item.account.accountManager.firstname} ${item.account.accountManager.lastname}`
        }

        // eslint-disable-next-line no-param-reassign
        item.bundleDisplayName = this.$options.filters.assetBundleDisplayName(item)

        // eslint-disable-next-line no-param-reassign
        item.medialineBusinessUnitDisplayName = this.$options.filters.medialineBusinessUnitName(item.medialineBusinessUnitId)
      })

      return process(assets, this.dataState)
    },
  },
  mounted() {
    if (this.isHost === false) {
      // remove some colums for tenants
      this.columns.splice(this.columns.findIndex(e => e.field === 'dailyCheckCellTemplate'), 1)
    }
  },
  created() {
    const config = {
      options: {
        includeDefaultOu: true,
      },
      requestConfig: {
        disableTenantFilter: true,
      },
    }
    this.$store.dispatch('assetList/fetchAllAssets', config)
  },
  methods: {
    ...mapMutations({
      setAssetTodayCheckupStatus: 'assetList/SET_ASSET_TODAYCHECKUPSTATUS',
      setAssetCheckupLoading: 'assetList/SET_ASSET_CHECKUPLOADING',
    }),
    async onDailyCheckClick(asset) {
      this.setAssetCheckupLoading({ assetId: asset.id, isLoading: true })

      const todayCheckupResult = await DailyCheckupService.getCheckupsOfTodayAsync({ assetIds: [asset.id] })
      if (todayCheckupResult.items.length === 0) {
        // Checkup does not exists for today yet, create new succeess checkup
        try {
          const checkupResult = await DailyCheckupService.createAsync({ assetId: asset.id, status: 0 }, { disableTenantFilter: true }) // Create checkup
          this.setAssetTodayCheckupStatus({ assetId: asset.id, todayCheckupStatus: checkupResult.status }) // mutate in store
        } catch (err) {
          this.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        }
      } else {
        // Checkup already exists for today, show Checkup Modal
        this.$refs.dailyCheckModal.show(asset.id)
      }

      this.setAssetCheckupLoading({ assetId: asset.id, isLoading: false })
    },
    onDailyCheckupUpdate(e) {
      const { checkup } = e
      this.setAssetTodayCheckupStatus({ assetId: checkup.asset.id, todayCheckupStatus: checkup.status }) // mutate in store
    },
  },
}
</script>
