<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          v-model="isShow"
          :size="isValidForDailyCheck ? 'xl' : 'sm'"
          :title="modalTitle"
        >
          <b-overlay
            :show="isLoading"
          >
            <!-- Some spacing for loading circle of overlay -->
            <div
              v-if="isLoading"
              class="pt-5 pb-5"
            />

            <b-alert
              variant="warning"
              :show="asset.hasProActiveService && isHost === false"
            >
              <div class="alert-body">
                <span>The asset has ProActive service. MentIQ will make the ProActive service checkups.</span>
              </div>
            </b-alert>

            <b-alert
              variant="warning"
              :show="asset.hasProActiveService === false && isHost === true"
            >
              <div class="alert-body">
                <span>You can only create daily checks for assets with ProActive service.</span>
              </div>
            </b-alert>

            <b-row v-if="isValidForDailyCheck">
              <b-col cols="4">
                <b-form-group
                  label="Daily check status"
                >
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="form.status"
                    name="radio-sub-component"
                  >
                    <b-form-radio :value="0">
                      Ok
                    </b-form-radio>
                    <b-form-radio :value="1">
                      Error resolved
                    </b-form-radio>
                    <b-form-radio :value="2">
                      Error not resolved
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <validation-provider
                  v-slot="validationContext"
                  name="Note"
                  :rules="{ max: 10000 }"
                >
                  <b-form-group
                    label="Note:"
                    label-for="input-note"
                    class="note-form-group"
                  >
                    <span class="muted float-right">
                      <b-link @click="useNoteOfDayBefore">
                        Use note of day before
                      </b-link>
                    </span>
                    <b-form-textarea
                      id="input-note"
                      v-model="form.note"
                      rows="10"
                      name="input-note"
                      placeholder="Enter notes"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-note-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-note-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <table
                  v-if="todayCheckup && todayCheckup.checkedBy"
                  class="table table-sm table-borderless"
                >
                  <tbody>
                    <tr>
                      <th>Checked by</th>
                      <td>
                        {{ todayCheckup.checkedBy.firstname }} {{ todayCheckup.checkedBy.lastname }}<br>
                        {{ todayCheckup.checkedBy.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>Checked on</th>
                      <td>{{ todayCheckup.checkedOn | formatDateTime }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col cols="8">
                <div class="asset-checkup-list-wrapper">
                  <AssetCheckupList
                    :checkups="previousCheckups"
                  />
                </div>
              </b-col>
            </b-row>

            <IncidentList
              v-if="asset && asset.id"
              :asset="asset"
            />
          </b-overlay>

          <template #modal-footer="{ cancel }">
            <b-button
              variant="secondary"
              :disabled="isLoading"
              @click="cancel()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click="save($event, false)"
            >
              Save
            </b-button>
            <b-button
              v-show="form.status != 0"
              :disabled="isLoading"
              variant="outline-primary"
              @click="save($event, true)"
            >
              Save and create ticket
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BOverlay, BAlert, BButton,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormRadioGroup, BFormRadio,
  BLink,
} from 'bootstrap-vue'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { mapGetters } from 'vuex'
import moment from 'moment'
import AssetCheckupList from './AssetCheckupList.vue'
import AssetService from '@/service/asset.service'
import DailyCheckupService from '@/service/dailycheckup.service'
import IncidentList from '@/components/incidents/IncidentList.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BOverlay,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BLink,
    ValidationObserver,
    ValidationProvider,
    AssetCheckupList,
    IncidentList,
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      asset: {},
      previousCheckups: [],
      todayCheckup: {},
      form: {
        note: '',
        status: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    modalTitle() {
      if (this.isLoading) {
        return 'Daily check is loading...'
      }

      if (this.asset.hostname) {
        return `Daily check for ${this.asset.hostname}`
      }
      return `Daily check for ${this.asset.systemNumber}`
    },
    isValidForDailyCheck() {
      // host can check ProActive service assets
      if (this.asset.hasProActiveService && this.isHost) {
        return true
      }

      // tenant can check none ProActive service assets
      if (this.asset.hasProActiveService === false && this.isHost === false) {
        return true
      }

      return false
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    show(assetId) {
      // Reset data
      this.asset = {}
      this.previousCheckups = []
      this.todayCheckup = {}
      this.form = {
        note: '',
        status: 0,
      }

      this.isShow = true
      this.isLoading = true

      AssetService.getAsync(assetId, { disableTenantFilter: true })
        .then(result => {
          this.asset = result
        })
        .finally(() => {
          this.isLoading = false
        })

      const today = moment()
      DailyCheckupService.getListAsync({ assetId, maxResultCount: 100, sorting: 'CheckedOn desc' }, { disableTenantFilter: true })
        .then(result => {
          this.previousCheckups = result.items
          if (this.previousCheckups.length > 0) {
            const lastCheckupDate = this.previousCheckups[0].checkedOn
            if (moment(lastCheckupDate).isSame(today, 'd')) {
              [this.todayCheckup] = this.previousCheckups
              this.form = {
                note: this.todayCheckup.note,
                status: this.todayCheckup.status,
              }
            }
          }
        })
    },
    save(event, createTicket) {
      event.preventDefault()
      const self = this

      DailyCheckupService.updateAsync(this.todayCheckup.id, this.form, { disableTenantFilter: true })
        .then(result => {
          self.$toast.success('Updated', {
            icon: true,
          })

          this.$emit('dailyCheckupUpdate', {
            checkup: result,
          })

          if (createTicket === true) {
            const routeData = self.$router.resolve({ name: 'asset-create-ticket', params: { id: self.asset.id }, query: { checkupId: result.id, returnPath: self.$router.currentRoute.path } })
            window.open(routeData.href, '_blank')
          }

          this.isShow = false
        })
        .catch(err => {
          const errors = mapServerFieldValidationErrors(err.response, {
            note: 'Note',
            status: 'Status',
          })

          self.$refs.observer.setErrors(errors)

          self.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        })
    },
    useNoteOfDayBefore() {
      const yesterday = moment().subtract(1, 'days')
      for (let i = 0; i < this.previousCheckups.length; i += 1) {
        if (moment(this.previousCheckups[i].checkedOn).isSame(yesterday, 'd')) {
          this.form.note = this.previousCheckups[i].note
          break
        }
      }
    },
  },
}
</script>

<style scoped>
    .note-form-group >>> label {
        float: left;
    }

    .asset-checkup-list-wrapper {
      min-height: 100px;
      max-height: 420px;
      overflow-y: scroll;
      margin-bottom: 20px;
    }
</style>
